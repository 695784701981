.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #1e2833;
  text-align: center;
  height: 100px;
  z-index: 2;
}
