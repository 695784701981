@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  font-family: "Montserrat";
  height: 100vh;
}

.input-field {
  margin-bottom: 1em !important;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25) !important;
}

.flex-container {
  display: flex;
  align-items: center;
  height: 100vh;
}

hr {
  border-top: 1px solid rgb(233, 233, 233);
}

.container {
  width: 500px;
  margin: 0 auto;
  padding: 10em 4em;
  box-sizing: content-box;
  text-align: center;
  border-radius: 4px;

  border: 1px solid #d1d5da;
}

.container-fullpage {
  width: 50em;
  margin: 0 auto;
  padding: 7em 2em 2em 2em;
  box-sizing: content-box;
  text-align: left;
}

.red-text {
  color: #c00000;
}

.green-text {
  color: #00a000;
}

h1 {
  padding-bottom: 0.5em !important;
}

.btn-block {
  margin-bottom: 0.5em;
}

@media (max-width: 768px) {
  body {
    font-size: 12px !important;
  }

  .container {
    width: calc(100% - 10em);
  }

  .container-fullpage {
    width: calc(100% - 4em);
  }
}

.ant-btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-warning {
  color: #fff !important;
  background-color: #eca52b !important;
  border-color: #eca52b !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
