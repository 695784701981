@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap);
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  font-family: "Montserrat";
  height: 100vh;
}

.input-field {
  margin-bottom: 1em !important;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25) !important;
}

.flex-container {
  display: flex;
  align-items: center;
  height: 100vh;
}

hr {
  border-top: 1px solid rgb(233, 233, 233);
}

.container {
  width: 500px;
  margin: 0 auto;
  padding: 10em 4em;
  box-sizing: content-box;
  text-align: center;
  border-radius: 4px;

  border: 1px solid #d1d5da;
}

.container-fullpage {
  width: 50em;
  margin: 0 auto;
  padding: 7em 2em 2em 2em;
  box-sizing: content-box;
  text-align: left;
}

.red-text {
  color: #c00000;
}

.green-text {
  color: #00a000;
}

h1 {
  padding-bottom: 0.5em !important;
}

.btn-block {
  margin-bottom: 0.5em;
}

@media (max-width: 768px) {
  body {
    font-size: 12px !important;
  }

  .container {
    width: calc(100% - 10em);
  }

  .container-fullpage {
    width: calc(100% - 4em);
  }
}

.ant-btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-warning {
  color: #fff !important;
  background-color: #eca52b !important;
  border-color: #eca52b !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.cart {
  right: 0;
  padding: 1em 1em 2em 1em;
  background: #f6f8fa;
  text-align: center;
  z-index: 2;
  overflow-y: auto;
}

.checkout.cart {
  position: static;
  width: 100%;
  box-shadow: none;
  font-size: 1.3em;
}

.checkout .ant-card {
  font-size: 1em;
}

.cart-item {
  margin: 1.5em 0;
}
.cart-parent {
  width: 100%;
  padding: 1em 1em;
}

.cart-item .ant-card-body {
  padding: 0;
  display: flex;
  width: 100%;
}

.cart-item-image {
  width: 4em;
}

.checkout .cart-item-image {
  width: 5em;
}

.cart-item-info {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.25em 0.5em 1em 0.5em;
  width: 100%;
}
.cart-item-category {
  color: #999;
}
.cart-item-qty {
  display: flex;
  justify-content: flex-end;
}

.total {
  background: #fff;
  text-align: left;
  padding: 1em;
  margin-bottom: 1em;
}
.total-item {
  display: flex;
  justify-content: space-between;
}
.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-shipping {
  padding: 2em;
  /* Reduce 70px of header and 100px of footer
  ** Make footer display at bottom even if less content on main page
  */
  min-height: calc(100vh - 170px);
}
.checkout-container {
  padding: 70px 0em 0em 0em;
}
.checkout-row {
  display: block;
  margin: 1.5em auto;
}

.addresses {
  width: 100%;
}
.address {
  margin-right: 20px;
  margin-bottom: 20px;

  /* height: 100%; */
}
.address-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 20px;
  white-space: pre-wrap;
  border: 1px solid #999;
  width:50%;
  max-width: 420px;
}
.address-text {
  width: 100%;
}

.new-address {
  width: 30em;
  margin-right: 1em;
  margin-bottom: 1em;
}

label.ant-radio-button-wrapper {
  display: block;
  height: calc(100% - 2.5em);
  padding: 0px;
}

.delete-address-btn {
  width: 100%;
  height: 2.5em;
}
.checkout-cart {
  /* height: 100vh; */
  background: #f6f8fa;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #1e2833;
  text-align: center;
  height: 100px;
  z-index: 2;
}

.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  padding: 0.75em 2em;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  height: 70px;
}

.profile-image {
  height: 30px;
  padding-right: 0.5em;
}

.header-title {
  font-size: 1.5em;
  cursor: pointer;
}

.header-action {
  display: flex;
  align-items: center;
}

.header-info {
  padding-right: 2em;
}
.header-link {
  padding: 0em 1em;
  cursor: pointer;
  color: #1890ff;
}

.home-buttons {
  display: flex;
  justify-content: center;
}
.btn-block {
  width: -webkit-max-content;
  width: max-content;
  margin: 10px;
}
.home-welcome-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.home-welcome-text img {
  margin: 3px 10px 0px 10px;
}

.product {
  margin: 1em 2em;
  overflow: hidden;
}

.product .ant-card-body {
  padding: 0;
  width: 100%;
}

.product.ant-card-hoverable:hover {
  border-color: #d5d5d5;
}

.product-image {
  height: 250px;
  width: 100%;
}
.product-image img {
  object-fit: cover;
  object-position: top;
  display: block;
  height: 100%;
  width: 100%;
}

.product-info {
  width: 100%;
  text-align: left;
}

.product-info-text,
.product-info-utility {
  padding: 1em 1em;
}

.product-title {
  font-size: 1.3em;
}

.product-cost {
  font-size: 1.5em;
}

.product-rating {
  font-size: 1em;
  margin-bottom: 2em;
}

.product-category {
  color: #909090;
}

.search-container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* Reduce 70px of header and 100px of footer 
  ** Make footer display at bottom even if less content on main page
  ** eg: when api doesn't return products
  */
  min-height: calc(100vh - 170px);
  margin-top: 70px;
  padding: 1.5em 0em;
}
.search-cart {
  margin-top: 70px;
  background: #f6f8fa;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

/* Set width for the search bar */
span.ant-input-search {
  width: 30%;
}
.thanks-container {
  background: #dcffe4;
  margin: 120px auto;
  width: -webkit-max-content;
  width: max-content;
  padding: 5em;
  box-sizing: content-box;
  text-align: center;
}
.thanks-line {
  font-size: 1.25em;
  margin: 1.5em 0;
}

